
import { Vue, Component, Prop } from "vue-property-decorator";
import { Location } from "vue-router";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { Action } from "vuex-class";

export interface ServiceItem {
    name: string;
    route: Location;
    text: string;
    icon: string;
    buttonText: string;
}

@Component
export default class ServiceProvided extends Vue {
    @Prop({ required: true, type: Object }) item!: ServiceItem;
    @Action("setNavRoute") setNavRoute!: (route: string | null) => void;

    registerCta() {
        this.setNavRoute(this.item.route.name ?? null);
        addAnalyticsEvent("landing_page_cta_clicked", { item: this.item.name });
    }
}
